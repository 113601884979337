const api_link = "https://routes.dfcapitals.com/god";
const access_key =
  "040ec1ee950ffc53291f6df0ffc30325-a1d41284cbad95a80f076eed39859381.77004ea213d5fc71acf74a8c9c6795fb";

let access = {
  sr: localStorage.getItem("server"),
  jwt: localStorage.getItem("token"),
  g_id: localStorage.getItem("godsId"),
};

export async function apiCall(path, data, options = {}) {
  try {
    const isFormData = options.isFormData || false;
    console.log("isFormData", isFormData);
    let requestBody;
    let headers = {};
    let apiEndpoint = api_link;

    if (isFormData) {
      apiEndpoint = "http://34.1.135.254:28528";
      // Handle FormData (file uploads)
      console.log("data", data);
      requestBody = data; // data is already a FormData object
      requestBody.append("access_key", access_key);
      requestBody.append("sr", access.sr);
      requestBody.append("jwt", access.jwt);
      requestBody.append("g_id", access.g_id);
      // Don't set Content-Type for FormData - browser will set it automatically
    } else {
      // Handle regular JSON requests
      requestBody = JSON.stringify({
        data: { ...data, ...access },
        access_key: access_key,
      });
      headers["Content-Type"] = "application/json";
    }

    const response = await fetch(apiEndpoint + path, {
      method: options.method || "POST",
      headers: {
        ...headers,
        ...options.headers,
      },
      body: requestBody,
    });

    const jsonResponse = await response.json();
    console.log("jsonResponse", jsonResponse);

    // Handle response based on status and error flags
    if (jsonResponse.error === "False") {
      return jsonResponse;
    } else if (jsonResponse.status === "Logout") {
      localStorage.clear();
      window.location.href = "/"; // Redirect on logout
      return;
    } else {
      // Handle failure messages from the API
      alert(jsonResponse.message || "An error occurred");
      return jsonResponse;
    }
  } catch (error) {
    console.error("API call failed:", error, "-", path);
    throw error; // Propagate the error to the caller
  }
}

// export async function apiCall(path, data, options = {}) {
//   try {
//     const isFormData = options.isFormData || false;
//     let requestBody;
//     let headers = {};

//     if (isFormData) {
//       // Handle FormData (file uploads)
//       requestBody = data;
//       requestBody.append("access_key", access_key);
//       requestBody.append("sr", access.sr);
//       requestBody.append("jwt", access.jwt);
//       requestBody.append("g_id", access.g_id);
//       // Don't set Content-Type for FormData - browser will set it automatically
//     } else {
//       // Handle regular JSON requests
//       requestBody = JSON.stringify({
//         data: { ...data, ...access },
//         access_key: access_key,
//       });
//       headers["Content-Type"] = "application/json";
//     }

//     const response = await fetch(api_link + path, {
//       method: options.method || "POST",
//       headers: {
//         ...headers,
//         ...options.headers,
//       },
//       body: requestBody,
//     });

//     const jsonResponse = await response.json();
//     console.log("jsonResponse", jsonResponse);
//     if (jsonResponse.error === "False") {
//       return jsonResponse;
//     } else if (jsonResponse.status === "Logout") {
//       localStorage.clear();
//       window.location.href = "/";
//       return;
//     } else {
//       alert(jsonResponse.message);
//       return jsonResponse; // Return the response even if there's an error
//     }
//   } catch (error) {
//     console.error("API call failed:", error, "-", path);
//     throw error;
//   }
// }
// }
