import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import NumberFormatIn from "../../Common/NumberFormatIn";
import moment from "moment";
import { apiCall } from "../../../Backend/Backend";
import { FilterMatchMode } from "primereact/api";
import { COLORSCHEMEA } from "../../../ColorSchema/ColorSchema";
import TopbarMost from "../../Common/TopbarMost";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Filter from "./Filter/Filter";

const php = new Php();
// const height = window.innerHeight;
// const width = window.innerWidth;

export default function BankLedger({ clientData }) {
  const [loading, setLoading] = React.useState(false);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const [clientLedgerLogs, setClientLedgerLogs] = React.useState([]);
  const [selectedAdmin, setSelectedAdmin] = React.useState(null);
  const [adminsFilter, setAdminsFilter] = React.useState([]);
  const [date, setDate] = React.useState([
    moment().startOf("month").toDate(),
    new Date(),
  ]);
  const { addNotification } = useNotification();

  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filters["global"] ? filters["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  React.useEffect(() => {
    load_gig_bank_ledger();
  }, []);

  const load_gig_bank_ledger = async (e) => {
    if (!loading) {
      setLoading(true);

      const params = {
        from_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 00:00:00")
            : moment(date[0]).format("YYYY-MM-DD 00:00:00"),
        to_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 23:59:59")
            : moment(date[1]).format("YYYY-MM-DD 23:59:59"),
        a_id: selectedAdmin?.id == undefined ? 0 : selectedAdmin?.id,
      };

      const response = await apiCall("/load_gig_bank_ledger", params);

      setLoading(false);
      setClientLedgerLogs(response.ledger_logs);
      setAdminsFilter(response.admins);
    }
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
        }}
      >
        <InputText
          style={{ width: 140 }}
          type="search"
          id="input"
          value={value || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder="Search"
        />
      </div>
      <Filter
        selectedAdmin={selectedAdmin}
        adminsFilter={adminsFilter}
        setSelectedAdmin={(e) => setSelectedAdmin(e)}
        date={date}
        setDate={(e) => setDate(e)}
        clearFilter={() => load_gig_bank_ledger("clear")}
        load_transactions={load_gig_bank_ledger}
      />
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          label="PDF"
          style={{
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
            color: COLORSCHEMEA.ORANGECOLOR,
            backgroundColor: "#fff",
            borderColor: COLORSCHEMEA.ORANGECOLOR,
          }}
          // onClick={exportPdf}
          data-pr-tooltip="PDF"
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          label="XLS"
          style={{
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
            color: COLORSCHEMEA.GREENCOLOR,
            backgroundColor: "#fff",
            borderColor: COLORSCHEMEA.GREENCOLOR,
          }}
          // onClick={exportExcel}
          data-pr-tooltip="EXCEL"
        />
      </div>
    </div>
  );

  return (
    <>
      <TopbarMost
        backgroundColor={COLORSCHEMEA.WHITECOLOR}
        textColor={COLORSCHEMEA.THEMEFONT}
        cmp={filterbar}
        noSidebar
      />
      <div className="card">
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          value={clientLedgerLogs}
          size="small"
          paginator
          dataKey="id"
          rows={10}
          loading={loading}
          scrollHeight="flex"
          tableStyle={{ minWidth: "50rem" }}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          rowsPerPageOptions={[10, 15, 30, 50, 100]}
          emptyMessage="No records found"
          globalFilterFields={[
            "to_admin_username",
            "remarks",
            "date_created",
            "credit",
            "debit",
            "new_balance",
          ]}
        >
          <Column
            style={{ width: "10%" }}
            sortable
            showFilterMenu={false}
            field="date_created"
            header="Date/Time"
            body={(rowData) => (
              <div>{moment(rowData.date_created).format("DD-MMM-YY")}</div>
            )}
          />
          <Column
            style={{ width: "10%" }}
            sortable
            showFilterMenu={false}
            field="to_admin_username"
            header="Admin"
            body={(rowData) => <div>{rowData.to_admin_username}</div>}
          />
          <Column
            style={{ width: "30%" }}
            sortable
            showFilterMenu={false}
            field="remarks"
            header="Remarks"
          />
          <Column
            sortable
            style={{ width: "10%" }}
            showFilterMenu={false}
            field="credit"
            sortField="credit"
            sortFunction={(event) => {
              const { data, field, order } = event;
              return [...data].sort((a, b) => {
                const valueA = parseFloat(a[field]) || 0;
                const valueB = parseFloat(b[field]) || 0;
                return order === 1 ? valueA - valueB : valueB - valueA;
              });
            }}
            body={(rowData) =>
              rowData.in_out == 0 ? (
                <div style={{ color: COLORSCHEMEA.GREENCOLOR }}>
                  {Math.abs(rowData.credit)}
                </div>
              ) : null
            }
            header="Credit"
          />
          <Column
            sortable
            style={{ width: "10%" }}
            showFilterMenu={false}
            field="debit"
            sortField="debit"
            sortFunction={(event) => {
              const { data, field, order } = event;
              return [...data].sort((a, b) => {
                const valueA = parseFloat(a[field]) || 0;
                const valueB = parseFloat(b[field]) || 0;
                return order === 1 ? valueA - valueB : valueB - valueA;
              });
            }}
            body={(rowData) =>
              rowData.in_out == 1 ? (
                <div style={{ color: COLORSCHEMEA.REDCOLOR }}>
                  {Math.abs(rowData.debit)}
                </div>
              ) : null
            }
            header="Debit"
          />

          <Column
            sortable
            style={{ width: "10%" }}
            showFilterMenu={false}
            field="new_balance"
            body={(rowData) => (
              <div
                style={{
                  fontWeight: "bolder",
                }}
              >
                <NumberFormatIn value={rowData.new_balance} />
              </div>
            )}
            header="Balance"
          />
        </DataTable>
      </div>
    </>
  );
}
