import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSidebar } from "../Sidebar/SidebarContext";
import { NewTheme } from "../Theme/Theme";
import { COLORSCHEMEA } from "../../ColorSchema/ColorSchema";

export default function TopbarMost({
  height = 55,
  noSidebar,
  onlyHeader,
  name,
  subTitle,
  backgroundColor = COLORSCHEMEA.THEMEFONT,
  textColor = "white",
  cmp,
}) {
  const { toggleSidebar } = useSidebar();
  return (
    <div
      style={{
        height,
        display: "flex",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: "lightgray",
        background: backgroundColor,
        color: textColor,
      }}
    >
      {noSidebar || onlyHeader ? null : (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 50,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
            onClick={toggleSidebar}
          >
            <FontAwesomeIcon
              style={{ height: 20, width: 20 }}
              icon={faBars}
              size="lg"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              flex: 1,
              minWidth: 250,
            }}
          >
            <div
              style={{
                marginLeft: 5,
                fontWeight: "bold",
                fontSize: 18,
              }}
            >
              {name}
            </div>
            {subTitle && (
              <div
                style={{
                  marginLeft: 5,
                  fontWeight: "bold",
                  fontSize: 12,
                  textAlign: "left",
                  flex: 1,
                }}
              >
                {subTitle}
              </div>
            )}
          </div>
        </div>
      )}
      {onlyHeader ? (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginLeft: 5,
              fontWeight: "bold",
              fontSize: 16,
              textAlign: "left",
              flex: 1,
            }}
          >
            {name}
          </div>
        </div>
      ) : null}
      <div style={{ flex: 3 }}>{cmp}</div>
    </div>
  );
}
