import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import styled from "styled-components";

const ToolbarContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  font-weight: bold;
`;

const ToolbarContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const SearchContainer = styled.div`
  .p-inputtext {
    height: 40px;
  }
`;

const UploadContainer = styled.div`
  display: flex;
  gap: 8px;

  .p-fileupload-choose {
    height: 40px;
    background-color: #2ea44f;
    border-color: #2ea44f;

    &:hover {
      background-color: #2c974b !important;
      border-color: #2c974b !important;
    }
  }
`;

const FileNameDisplay = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #495057;

  i {
    color: #2ea44f;
  }
`;

const SubmitButton = styled.button`
  height: 40px;
  padding: 0.5rem 1rem;
  background-color: #2ea44f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    background-color: #2c974b;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const OverviewBar = ({
  searchValue,
  onSearchChange,
  onFileUpload,
  maxFileSize = 1000000,
  acceptedFileTypes = ".xlsx,.xls",
}) => {
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileUploadRef = useRef(null);

  const handleFileSelect = (e) => {
    setSelectedFileName(e.files[0].name);
    setSelectedFile(e.files[0]);
  };

  const handleSubmit = async () => {
    if (selectedFile) {
      const uploadSuccess = await onFileUpload({ files: [selectedFile] });

      if (uploadSuccess) {
        // Only clear if upload was successful
        setSelectedFile(null);
        setSelectedFileName(null);
        if (fileUploadRef.current) {
          fileUploadRef.current.clear();
        }
      }
    }
  };

  return (
    <ToolbarContainer>
      <ToolbarContent>
        <SearchContainer>
          <InputText
            style={{ width: 140 }}
            type="search"
            value={searchValue || ""}
            onChange={onSearchChange}
            placeholder="Search"
          />
        </SearchContainer>
        <UploadContainer>
          <FileUpload
            ref={fileUploadRef}
            mode="basic"
            name="file"
            accept={acceptedFileTypes}
            maxFileSize={maxFileSize}
            customUpload
            auto={false}
            chooseLabel="Select Excel"
            onSelect={handleFileSelect}
          />
          {selectedFileName && (
            <>
              <FileNameDisplay>
                <i className="pi pi-file-excel" />
                <span>{selectedFileName}</span>
              </FileNameDisplay>
              <SubmitButton onClick={handleSubmit} disabled={!selectedFile}>
                Upload File
              </SubmitButton>
            </>
          )}
        </UploadContainer>
      </ToolbarContent>
    </ToolbarContainer>
  );
};

export default OverviewBar;
