import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { apiCall } from "../../../Backend/Backend";
import LoadingComponent from "../../Common/LoadingComponent";

const php = new Php();
const height = window.innerHeight;

export default function LoginLogs({ clientData }) {
  const [loading, setLoading] = React.useState(false);
  const [clientRejectionLogs, setClientRejectionLogs] = React.useState([]);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_users_login();
  }, [clientData.id]);

  const load_users_login = async () => {
    if (!loading) {
      setLoading(true);
      let sendData = {
        m_id: clientData.id,
        a_id: clientData.admin_id,
      };
      const response = await apiCall("/load_masters_login", sendData);

      setLoading(false);
      setClientRejectionLogs(response.login_logs);
    }
  };

  return (
    <div className="card">
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollHeight={height - 200}
        scrollable
        // filters={filters}
        value={clientRejectionLogs}
        size="small"
        paginator
        rows={10}
        loading={loading}
        loadingIcon={() => (
          <LoadingComponent
            showRing
            showText
            withPulse={true}
            position="center"
          />
        )}
        rowsPerPageOptions={[10, 30, 50, 100]}
      >
        <Column
          style={{ width: "30%" }}
          showFilterMenu={false}
          field="remarks"
          header="Devices"
        ></Column>
        <Column
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="version"
          header="Version"
        ></Column>
        <Column
          style={{ width: "20%" }}
          sortable
          showFilterMenu={false}
          field="ip"
          header="IP"
        ></Column>
        <Column
          style={{ width: "40%" }}
          sortable
          showFilterMenu={false}
          field="date_created"
          header="Date/Time"
        ></Column>
      </DataTable>
    </div>
  );
}
