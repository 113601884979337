import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { AiOutlineCalculator } from "react-icons/ai";
import { BiLineChart } from "react-icons/bi"; // Live Profit/Loss
import { MdOutlineReceiptLong } from "react-icons/md"; // Booked Profit/Loss
import { apiCall } from "../../../../../Backend/Backend";
import { COLORSCHEMEA } from "../../../../../ColorSchema/ColorSchema";
import LoadingComponent from "../../../../Common/LoadingComponent";
import NumberFormatIn from "../../../../Common/NumberFormatIn";
import ClientDetailsCard from "../../ClientDetailsCard";

export default function LiveSummaryModal({
  visible,
  position,
  setVisible,
  clientUsername,
  usersId,
  weekly_pl,
  credit,
  blocked_limit,
}) {
  const [loading, setLoading] = React.useState(true);
  const [activeSummary, setActiveSummary] = React.useState("");
  const [totalPL, setTotalPL] = React.useState("");

  React.useEffect(() => {
    load_masters_active_summary();
  }, [usersId]);

  const load_masters_active_summary = async () => {
    let sendData = {
      m_id: usersId,
    };

    const response = await apiCall("/load_masters_active_summary", sendData);
    let PL = parseFloat(weekly_pl) + parseFloat(response.data);
    setTotalPL(parseFloat(PL).toFixed(0));
    setActiveSummary(response.data);
    setLoading(false);
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
        style={{ color: COLORSCHEMEA.THEMEFONT }}
      />
      {/* <Button
        label="PDF"
        icon="pi pi-download"
        onClick={() => alert("PDF")}
        autoFocus
        style={{ backgroundColor: COLORSCHEMEA.MainColor }}
      /> */}
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: COLORSCHEMEA.WHITECOLOR }}>
          {"  Client Live Summary : "}
          {clientUsername && clientUsername}
        </div>
      }
      headerStyle={{
        backgroundColor: COLORSCHEMEA.THEMEFONT,
      }}
      visible={visible}
      position={position}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      {loading ? (
        <LoadingComponent showRing showText position="center" />
      ) : (
        <div style={{ marginTop: 20 }}>
          <ClientDetailsCard
            title={"Booked Profit / Loss "}
            details={
              <NumberFormatIn value={parseFloat(weekly_pl).toFixed(0)} />
            }
            color={
              weekly_pl > 0 ? COLORSCHEMEA.GREENCOLOR : COLORSCHEMEA.REDCOLOR
            }
            Icon={() => (
              <MdOutlineReceiptLong
                size={24}
                color={
                  activeSummary != null && parseFloat(activeSummary) > 0
                    ? COLORSCHEMEA.GREENCOLOR
                    : COLORSCHEMEA.REDCOLOR
                }
              />
            )}
          />
          <ClientDetailsCard
            title={"Live Profit / Loss "}
            details={
              activeSummary == null ? (
                0
              ) : (
                <NumberFormatIn value={parseFloat(activeSummary).toFixed(0)} />
              )
            }
            color={
              activeSummary != null && parseFloat(activeSummary) > 0
                ? COLORSCHEMEA.GREENCOLOR
                : COLORSCHEMEA.REDCOLOR
            }
            Icon={() => (
              <BiLineChart
                size={24}
                color={
                  activeSummary != null && parseFloat(activeSummary) > 0
                    ? COLORSCHEMEA.GREENCOLOR
                    : COLORSCHEMEA.REDCOLOR
                }
              />
            )}
          />
          <ClientDetailsCard
            title={"Total Profit / Loss "}
            details={
              totalPL == null || isNaN(totalPL) ? (
                0
              ) : (
                <NumberFormatIn value={parseFloat(totalPL).toFixed(0)} />
              )
            }
            color={
              totalPL != null && parseFloat(totalPL) > 0
                ? COLORSCHEMEA.GREENCOLOR
                : COLORSCHEMEA.REDCOLOR
            }
            Icon={() => (
              <AiOutlineCalculator
                size={24}
                color={
                  totalPL != null && parseFloat(totalPL) > 0
                    ? COLORSCHEMEA.GREENCOLOR
                    : COLORSCHEMEA.REDCOLOR
                }
              />
            )}
          />
        </div>
      )}
    </Dialog>
  );
}
