import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import NumberFormatIn from "../../Common/NumberFormatIn";
import { apiCall } from "../../../Backend/Backend";
import moment from "moment";

const php = new Php();
const height = window.innerHeight;

export default function DepositLedgerLogs({ clientData }) {
  const [loading, setLoading] = React.useState(false);
  const [clientLedgerLogs, setClientLedgerLogs] = React.useState([]);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_admins_ledger();
  }, [clientData.id]);

  const load_admins_ledger = async () => {
    if (!loading) {
      setLoading(true);
      let sendData = {
        a_id: clientData.id,
      };

      const response = await apiCall("/load_admins_deposit", sendData);
      setLoading(false);

      setClientLedgerLogs(response.ledger_logs);
    }
  };

  return (
    <div className="card">
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollHeight={height - 200}
        scrollable
        // filters={filters}
        value={clientLedgerLogs}
        size="small"
        paginator
        rows={10}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 100]}
      >
        <Column
          style={{ width: "20%" }}
          sortable
          showFilterMenu={false}
          field="date_created"
          header="Date/Time"
          body={(rowData) => (
            <div>
              {moment(rowData.date_created).format("DD-MMM-YY")}
              <br />
              {moment(rowData.date_created).format("hh:mm:ss")}
            </div>
          )}
        />
        <Column
          style={{ width: "10%" }}
          sortable
          showFilterMenu={false}
          field="remarks"
          header="Remarks"
        />
        <Column
          sortable
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="credit"
          body={(rowData) =>
            rowData.in_out == 0 ? (
              <div style={{ color: NewTheme.greencolor }}>
                {Math.abs(rowData.credit)}
              </div>
            ) : null
          }
          header="Credit"
        />
        <Column
          sortable
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="debit"
          body={(rowData) =>
            rowData.in_out == 1 ? (
              <div style={{ color: NewTheme.redcolor }}>
                {Math.abs(rowData.debit)}
              </div>
            ) : null
          }
          header="Debit"
        />

        <Column
          sortable
          style={{ width: "30%" }}
          showFilterMenu={false}
          field="new_balance"
          body={(rowData) => (
            <div
              style={{
                fontWeight: "bolder",
              }}
            >
              {/* <NumberFormatIn value={rowData.old_balance} />
              <span
                style={{
                  color: NewTheme.MainColor,
                  fontWeight: "bolder",
                  paddingRight: 5,
                  paddingLeft: 5,
                }}
              >
                {"  ->  "}
              </span> */}
              <NumberFormatIn value={rowData.new_balance} />
            </div>
          )}
          header="Balance"
        ></Column>
      </DataTable>
    </div>
  );
}
