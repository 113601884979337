import { Menubar } from "primereact/menubar";
import React, { useState } from "react";
import { FaRegListAlt } from "react-icons/fa";
import styled from "styled-components";
import { NewTheme } from "../Theme/Theme";
import LoginLogs from "./LoginLogs/LoginLogs";
import NewBasic from "./Overview/NewBasic";
import DepositLogs from "./DepositLogs/DepositLogs";
import ScriptClient from "./Script/ScriptClient";
import Edit from "./Edit/Edit";
import BankLedger from "./BankLedger/BankLedger";

const RightPanelContainer = styled.div`
  flex: 3;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: 0px solid black;
`;

const Tabs = styled.div`
  display: flex;
  background-color: white;
  // border-top: 1px solid gray;
  // border-left: 1px solid gray;
  border-bottom: 1px solid gray;
  // border-right: 1px solid gray;
`;

const Tab = styled.div`
  padding: 15px 30px;
  color: black;
  cursor: pointer;
  border-right: 1px solid gray;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};

  ${(props) =>
    props.active &&
    `
    background-color: ${NewTheme.lightmain};
    color: ${NewTheme.MainColor};
    border-color: ${NewTheme.MainColor};
    border-bottom: 3px solid ${NewTheme.MainColor}; 
  `}
`;

const TabContent = styled.div`
  padding: 10px;
`;

function RightSide(props) {
  const [menuselection, setMenu] = useState(0);

  const items = [
    {
      label: "Basic",
      icon: "pi pi-home",
      command: () => {
        setMenu(0);
      },
    },
    // {
    //   label: "Edit",
    //   icon: "pi pi-star",
    //   command: () => {
    //     setMenu(1);
    //   },
    // },

    // {
    //   label: "Scripts",
    //   icon: "pi pi-envelope",
    //   command: () => {
    //     setMenu(2);
    //   },
    // },
    {
      label: "Bank Ledger",
      icon: "pi pi-bolt",
      command: () => {
        setMenu(4);
      },
    },
    // {
    //   label: "Settlement ",
    //   icon: <FaRegListAlt style={{ marginRight: 10 }} />,
    //   command: () => {
    //     setMenu(4);
    //   },
    // },
    {
      label: "Login Logs",
      icon: "pi pi-pencil",
      command: () => {
        setMenu(5);
      },
    },
  ];

  return (
    <div style={{ flex: 1, display: "flex" }}>
      <div style={{ flex: 3, height: "90%", padding: 10 }}>
        <Menubar style={{ borderRadius: 0, marginBottom: 5 }} model={items} />
        {menuselection == 0 && <NewBasic />}
        {/* {menuselection == 1 && <Edit />} */}
        {/* {menuselection == 2 && <ScriptClient />} */}
        {/* {menuselection == 3 && <DepositLogs />} */}
        {menuselection == 4 && <BankLedger />}
        {menuselection == 5 && <LoginLogs />}
      </div>
    </div>
  );
}

export default RightSide;
