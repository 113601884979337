import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment/moment";
import { FilterMatchMode } from "primereact/api";
import { apiCall } from "../../Backend/Backend";
import { COLORSCHEMEA } from "../../ColorSchema/ColorSchema";
import LoadingComponent from "../Common/LoadingComponent";
import NumberFormatIn from "../Common/NumberFormatIn";
import TopbarMost from "../Common/TopbarMost";
import { useNotification } from "../Notification/NotificationContext";
import { NewTheme } from "../Theme/Theme";
import { CreateClientButton } from "../Transaction/Styled";
import OverviewBar from "./OverviewBar";

const height = window.innerHeight;

export default function EquityScripts() {
  const [scriptsData, setScriptsData] = useState([]);

  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);

  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [selectedFileName, setSelectedFileName] = React.useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  useEffect(() => {
    load_equity_scripts();
  }, []);

  const load_equity_scripts = async () => {
    const response = await apiCall("/get_all_scripts");
    setLoading(false);
    setScriptsData(response.scripts);
  };

  const upload_equity_scripts = async (event) => {
    try {
      setLoading(true);
      const file = event.files[0];

      const formData = new FormData();
      formData.append("excelFile", file);

      const response = await apiCall("/upload-excel", formData, {
        method: "POST",
        isFormData: true,
      });
      console.log("response", response);
      addNotification(response?.message, "success");
      return true;
    } catch (error) {
      console.error("Upload error:", error);
      addNotification("Failed to upload file", "error");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const changeEquityProperty = async (endpoint, symbol) => {
    try {
      setLoading(true);
      const response = await apiCall(endpoint, { symbol });
      if (response.error === "False") {
        addNotification(response.message, "success");
      } else {
        addNotification(response.message, "error");
      }
      await load_equity_scripts();
    } catch (error) {
      addNotification("Failed to process request", "error");
    } finally {
      setLoading(false);
    }
  };

  const change_equity_fresh_limit = (symbol) =>
    changeEquityProperty("/change_equity_fresh_limit", symbol);

  const change_equity_only_sq = (symbol) =>
    changeEquityProperty("/change_equity_only_sq", symbol);

  const change_equity_status = (symbol) =>
    changeEquityProperty("/change_equity_status", symbol);

  const cols = [
    { dataKey: "username", title: "Username" },
    { dataKey: "symbol", title: "Symbol" },
    { dataKey: "qty", title: "Qty" },
    { dataKey: "buy_sell", title: "B/S" },
    { dataKey: "price", title: "Price" },
    { dataKey: "high_rate", title: "High" },
    { dataKey: "low_rate", title: "Low" },
    { dataKey: "highlow_mid", title: "HL/MID" },
    { dataKey: "type", title: "Type" },
    { dataKey: "tick_time", title: "Tick Time" },
    { dataKey: "time_buy", title: "Process Time" },
    { dataKey: "process_msg", title: "Details" },
    { dataKey: "date_created", title: "Date" },
  ];

  const exportPdf = () => {
    // Your column and data definitions (replace these with your actual data)
    const doc = new jsPDF({
      orientation: "landscape",
    });

    doc.setFontSize(16);
    doc.text("Equity Scripts", 15, 15);

    scriptsData.forEach((row) => {
      // Convert the value to a number for loose equality check
      const highLowValue = Number(row.highlow_mid);
      const buySellValue = Number(row.buy_sell);
      const slTpValue = Number(row.sl_tp);

      // Update the values based on conditions
      row.highlow_mid = highLowValue == 0 ? "High-Low" : "Mid-Limit";
      row.buy_sell = buySellValue == 0 ? "Buy" : "Sell";
      row.type = buySellValue == 0 ? "Buy" : "Sell"; // Assuming 'type' is a string property

      const symbolValue =
        row.symbol + "\n" + moment(row.expiry_date).format("DD-MMM-YY");

      row.symbol = symbolValue;
      // Determine 'sl_tp' based on its value
      if (slTpValue == 0) {
        row.type += "-Stop";
      } else {
        row.type += "-Target";
      }
    });

    const headerStyles = {
      fillColor: "#4a6ba1",
      textColor: "#ffffff",
    };

    // Add content to the PDF using autoTable with modified data and custom header styles
    const tableStartY = 25; // Adjust the startY value based on the header size and layout
    doc.autoTable(cols, scriptsData, { startY: tableStartY, headerStyles });

    var pdfName = "Equity Scripts";
    // Save or display the PDF

    doc.save(pdfName + ".pdf");
  };

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  const overviewBar = (
    <OverviewBar
      searchValue={value}
      onSearchChange={onGlobalFilterChange}
      onFileUpload={upload_equity_scripts}
      maxFileSize={1000000}
      acceptedFileTypes=".xlsx,.xls"
    />
  );

  return (
    <div
      className="card"
      style={{
        paddingTop: 0,
        overflow: "hidden",
      }}
    >
      <TopbarMost
        background={"#ffffff"}
        name="Equity Scripts"
        cmp={overviewBar}
      />
      {/* <TopbarMost
        background={"#ffffff"}
        noSidebar
        height={35}
        name="Transactions"
        // cmp={}
      /> */}

      <div id={"stick"} style={{ position: "relative" }}>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 150}
          size="small"
          // filterDisplay="row"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={scriptsData}
          style={{ fontWeight: "bold", fontSize: 14, borderColor: "red" }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          // globalFilterFields={[ "symbol"]}
          paginator
          rows={15}
          loading={loading}
          loadingIcon={<LoadingComponent />}
          rowsPerPageOptions={[10, 25, 50, 100]}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
        >
          <Column
            field="name"
            showFilterMenu={false}
            header="Name"
            sortable
            filter
            body={(rowData) => <div>{rowData.name}</div>}
          />
          <Column
            field="symbol"
            showFilterMenu={false}
            header="Symbols"
            sortable
            filter
            body={(rowData) => (
              <>
                <div style={{ color: COLORSCHEMEA.THEMEFONT }}>
                  {rowData.symbol}
                </div>
              </>
            )}
          />
          <Column
            field="symbol_display"
            showFilterMenu={false}
            header="Symbols Display"
            sortable
            filter
            body={(rowData) => <>{rowData.symbol_display}</>}
          />

          <Column
            filter
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Date/Time"
            body={(rowData) => (
              <div>
                {moment(rowData.date_created).format("DD-MMM ")}
                <br />
                <span style={{ color: NewTheme.MainColor }}>
                  {moment(rowData.date_created).format("HH:mm:ss")}
                </span>
              </div>
            )}
          />
          <Column
            field="fresh_limit"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Fresh Limit"
            body={(rowData) => (
              <div style={{ display: "flex", gap: 5 }}>
                <CreateClientButton
                  active={rowData.fresh_limit == 1 ? true : false}
                  color={
                    rowData.fresh_limit == 1
                      ? COLORSCHEMEA.GREENCOLOR
                      : COLORSCHEMEA.REDCOLOR
                  }
                  onClick={() => change_equity_fresh_limit(rowData.symbol)}
                  tooltip="Save"
                  tooltipOptions={{
                    position: "bottom",
                    mouseTrack: true,
                    mouseTrackTop: 15,
                  }}
                  data-pr-tooltip={
                    rowData.fresh_limit != 1
                      ? "Disable Fresh Limit"
                      : "Enable Fresh Limit"
                  }
                >
                  {rowData.fresh_limit == 1 ? "Active" : "Inactive"}
                </CreateClientButton>
              </div>
            )}
          />
          <Column
            field="only_sq"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Only SQ"
            body={(rowData) => (
              <div style={{ display: "flex", gap: 5 }}>
                <CreateClientButton
                  active={rowData.only_sq == 1 ? true : false}
                  color={
                    rowData.only_sq == 1
                      ? COLORSCHEMEA.GREENCOLOR
                      : COLORSCHEMEA.REDCOLOR
                  }
                  onClick={() => change_equity_only_sq(rowData.symbol)}
                  details={rowData.only_sq == 1 ? "Yes" : "No"}
                  tooltip="Save"
                  tooltipOptions={{
                    position: "bottom",
                    mouseTrack: true,
                    mouseTrackTop: 15,
                  }}
                  data-pr-tooltip={
                    rowData.only_sq != 1 ? "Disable Only SQ" : "Enable Only SQ"
                  }
                >
                  {rowData.only_sq == 1 ? "Active" : "Inactive"}
                </CreateClientButton>
              </div>
            )}
          />
          <Column
            field="status"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Status"
            body={(rowData) => (
              <div style={{ display: "flex", gap: 5 }}>
                <CreateClientButton
                  active={rowData.status == 1 ? true : false}
                  color={
                    rowData.status == 1
                      ? COLORSCHEMEA.GREENCOLOR
                      : COLORSCHEMEA.REDCOLOR
                  }
                  onClick={() => change_equity_status(rowData.symbol)}
                  tooltip="Save"
                  tooltipOptions={{
                    position: "bottom",
                    mouseTrack: true,
                    mouseTrackTop: 15,
                  }}
                  data-pr-tooltip={
                    rowData.status != 1 ? "Disable Status" : "Enable Status"
                  }
                >
                  {rowData.status == 1 ? "Active" : "Inactive"}
                </CreateClientButton>
              </div>
            )}
          />
          <Column
            field="max_volume"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Max Volume"
            body={(rowData) => (
              <div>
                <NumberFormatIn value={rowData.max_volume} />
              </div>
            )}
          />
          {/* <Column
            field="volume_percent"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Volume Percent"
            body={(rowData) => <div>{rowData.volume_percent}</div>}
          /> */}
        </DataTable>
      </div>
    </div>
  );
}
