import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../Backend/Php";
import { useNotification } from "../Notification/NotificationContext";
import { NewTheme } from "../Theme/Theme";

import { apiCall } from "../../Backend/Backend";
import NumberFormatIn from "../Common/NumberFormatIn";
import TopbarMost from "../Common/TopbarMost";
import { COLORSCHEMEA } from "../../ColorSchema/ColorSchema";
import moment from "moment";

const php = new Php();
const height = window.innerHeight;

export default function GodLedger({ clientData }) {
  const [loading, setLoading] = React.useState(false);
  const [clientLedgerLogs, setClientLedgerLogs] = React.useState([]);
  const [selfLedgerLogs, setSelfLedgerLogs] = React.useState([]);
  const [masterLedgerLogs, setMasterLedgerLogs] = React.useState([]);
  const [uplineSum, setUplineSum] = React.useState(0);
  const [adminBalance, setAdminBalance] = React.useState(0);
  const [masterBalance, setMasterBalance] = React.useState(0);
  const [masterSum, setMasterSum] = React.useState(0);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_god_ledger();
  }, []);

  const load_god_ledger = async () => {
    if (!loading) {
      setLoading(true);

      const response = await apiCall("/load_god_ledger");
      setLoading(false);
      setSelfLedgerLogs(response.admin_ledger_logs);
      setUplineSum(response.upline_sum);
      setAdminBalance(response.admin_bal);
      setMasterBalance(response.master_balance);
      setMasterSum(response.master_sum);
      setMasterLedgerLogs(response.master_ledger_logs);
    }
  };

  const overviewBar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div
          style={{
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            flexDirection: "column",
            minWidth: 160,
            backgroundColor: "#ffff",
            padding: "0 10px",
            borderRadius: 4,
          }}
          // onClick={() => show()}
        >
          <div style={{ fontSize: 12, color: NewTheme.blackColor }}>Book</div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              backgroundColor: "#ffff",
              padding: "0 10px",
              borderRadius: 5,
              color:
                parseFloat(masterSum - uplineSum) >= 0
                  ? NewTheme.greencolor
                  : NewTheme.redcolor,
            }}
          >
            <NumberFormatIn
              value={parseFloat(masterSum - uplineSum).toFixed(2)}
            />
          </div>
        </div>
        <div
          style={{
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            flexDirection: "column",
            minWidth: 160,
            backgroundColor: "#ffff",
            padding: "0 10px",
            borderRadius: 4,
          }}
          // onClick={() => show()}
        >
          <div style={{ fontSize: 12, color: NewTheme.blackColor }}>
            Upline Amount
          </div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              backgroundColor: "#ffff",
              padding: "0 10px",
              borderRadius: 5,
              color: uplineSum >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
            }}
          >
            <NumberFormatIn value={parseFloat(uplineSum).toFixed(2)} />
          </div>
        </div>
        <div
          style={{
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            flexDirection: "column",
            minWidth: 200,
            backgroundColor: "#ffff",
            padding: "0 10px",
            borderRadius: 4,
          }}
        >
          <div
            style={{
              fontSize: 12,
              color: NewTheme.blackColor,
            }}
          >
            GIG Balance
          </div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              color:
                adminBalance >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
            }}
          >
            <NumberFormatIn value={parseFloat(adminBalance).toFixed(2)} />
          </div>
        </div>
        <div
          style={{
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            flexDirection: "column",
            minWidth: 160,
            backgroundColor: "#ffff",
            padding: "0 10px",
            borderRadius: 4,
          }}
        >
          <div style={{ fontSize: 12, color: NewTheme.blackColor }}>
            Downline Amount
          </div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              backgroundColor: "#ffff",
              padding: "0 10px",
              borderRadius: 5,
              color:
                masterBalance >= 0
                  ? COLORSCHEMEA.GREENCOLOR
                  : COLORSCHEMEA.REDCOLOR,
            }}
          >
            <NumberFormatIn value={masterSum} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginLeft: 10,
            minWidth: 160,
            backgroundColor: "#fff",
            padding: "0 10px",
            borderRadius: 4,
          }}
          // onClick={() => show()}
        >
          <div style={{ fontSize: 12, color: NewTheme.blackColor }}>
            Downline Balance
          </div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              backgroundColor: "#ffff",
              padding: "0 10px",
              borderRadius: 5,
              color:
                masterBalance >= 0
                  ? COLORSCHEMEA.GREENCOLOR
                  : COLORSCHEMEA.REDCOLOR,
            }}
          >
            <NumberFormatIn value={masterBalance} />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className="card"
      style={{
        paddingTop: 0,
        overflow: "hidden",
        height: "100vh",
      }}
    >
      <TopbarMost
        name={localStorage.getItem("godsUsername")?.toUpperCase() + "'s Ledger"}
        cmp={overviewBar}
      />
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          className="card"
          style={{
            width: "50%",
            borderRightWidth: 4,
            borderRightStyle: "solid",
            borderRightColor: COLORSCHEMEA.THEMEFONT,
            height: height - 60,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              height: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            UPLINE{" "}
          </div>
          <DataTable
            removableSort
            stripedRows
            showGridlines
            scrollHeight={height - 110}
            scrollable
            // filters={filters}
            value={selfLedgerLogs}
            size="small"
          >
            <Column
              style={{ width: "20%" }}
              sortable
              showFilterMenu={false}
              field="date_created"
              header="Date/Time"
              body={(rowData) => (
                <div>{moment(rowData.date_created).format("DD-MMM-YY")}</div>
              )}
            />
            <Column
              style={{ width: "20%" }}
              sortable
              showFilterMenu={false}
              field="remarks"
              header="Remarks"
            />
            <Column
              sortable
              style={{ width: "5%" }}
              showFilterMenu={false}
              field="upline_amt"
              body={(rowData) =>
                rowData.in_out == 0 ? (
                  <div style={{ color: NewTheme.greencolor }}>
                    {Math.abs(rowData.upline_amt)}
                  </div>
                ) : null
              }
              header="Credit"
            />
            <Column
              sortable
              style={{ width: "5%" }}
              showFilterMenu={false}
              field="upline_amt"
              body={(rowData) =>
                rowData.in_out == 1 ? (
                  <div style={{ color: NewTheme.redcolor }}>
                    {Math.abs(rowData.upline_amt)}
                  </div>
                ) : null
              }
              header="Debit"
            />

            <Column
              sortable
              style={{ width: "5%" }}
              showFilterMenu={false}
              field="new_balance"
              body={(rowData) => (
                <div
                  style={{
                    fontWeight: "bolder",
                  }}
                >
                  {/* <NumberFormatIn value={rowData.old_balance} />
                        <span
                          style={{
                            color: NewTheme.MainColor,
                            fontWeight: "bolder",
                            paddingRight: 5,
                            paddingLeft: 5,
                          }}
                        >
                          {"  ->  "}
                        </span> */}
                  <NumberFormatIn value={rowData.new_balance} />
                </div>
              )}
              header="Balance"
            ></Column>
          </DataTable>
        </div>
        <div
          className="card"
          style={{ width: "50%", display: "flex", flexDirection: "column" }}
        >
          <div
            style={{
              height: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            DOWNLINE{" "}
          </div>
          <DataTable
            removableSort
            stripedRows
            showGridlines
            scrollHeight={height - 110}
            scrollable
            // filters={filters}
            value={masterLedgerLogs}
            size="small"
          >
            <Column
              style={{ width: "10%" }}
              sortable
              showFilterMenu={false}
              field="username"
              header="Master"
              body={(rowData) => (
                <div style={{ color: COLORSCHEMEA.THEMEFONT }}>
                  {rowData.username}
                </div>
              )}
            />
            <Column
              sortable
              style={{ width: "10%" }}
              showFilterMenu={false}
              field="balance"
              body={(rowData) => (
                <div
                  style={{
                    color:
                      rowData.balance >= 0
                        ? COLORSCHEMEA.GREENCOLOR
                        : COLORSCHEMEA.REDCOLOR,
                  }}
                >
                  <NumberFormatIn value={rowData.balance} />
                </div>
              )}
              header="Balance"
            />
            <Column
              sortable
              style={{ width: "10%" }}
              showFilterMenu={false}
              field="upline_sum"
              body={(rowData) => (
                <div
                  style={{
                    color:
                      rowData.upline_sum >= 0
                        ? COLORSCHEMEA.GREENCOLOR
                        : COLORSCHEMEA.REDCOLOR,
                  }}
                >
                  <NumberFormatIn value={rowData.upline_sum} />
                </div>
              )}
              header=" Amount"
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
}
