import moment from "moment";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import Php from "../../Backend/Php";
import { COLORSCHEMEA } from "../../ColorSchema/ColorSchema";
import SocketContext from "../../SocketContext/SocketContext";
import { useNotification } from "../Notification/NotificationContext";
import { NewTheme } from "../Theme/Theme";
import BidaskCard from "./BidaskCard";

const php = new Php();

function WatchlistCard(props) {
  const item = props.item;
  const { addNotification } = useNotification();
  const socket = React.useContext(SocketContext);
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [high2, sethigh2] = useState(0);
  const [low2, setlow2] = useState(0);
  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ltp, setltp] = useState(0);
  const [open, setOpen] = useState(0);
  const [close, setClose] = useState(0);
  const [date, setDate] = useState(0);
  const [showManual, setShowManual] = useState(false);

  const [bidTick, setBidTick] = useState(false);
  const [askTick, setAskTick] = useState(false);

  const [username, setUsername] = useState("");
  const [qty, setQty] = useState("");
  const [price, setPrice] = useState("");

  useEffect(() => {
    socket.emit("giverate", item.script_id);
    socket.on("trade" + item.script_id, trade);
    socket.on("bidask" + item.script_id, bidask);
    return () => {
      socket.off("trade" + item.script_id, trade);
      socket.off("bidask" + item.script_id, bidask);
    };
  }, []);

  const manual_trade = (buy_sell) => {
    if (username == "") {
      addNotification("Enter Username", "error");
    } else if (qty <= 0) {
      addNotification("Enter Qty", "error");
    } else if (price <= 0) {
      addNotification("Enter Price", "error");
    } else {
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
        username: username,
        script_id: item.script_id,
        script_type: item.script_type,
        qty: qty,
        price: price,
        buy_sell: buy_sell,
      };

      php.manual_trade(data).then((r) => {
        if (r.error == "False") {
          addNotification(r.message, "success");
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const showModal = () => {
    setShowManual(!showManual);
  };

  const trade = (msg) => {
    if (msg == null) {
      // alert.error("Script is expire no trading available");
    } else {
      if (bid !== msg.Bid) {
        setbid(msg.Bid);
      }
      if (ask !== msg.Ask) {
        setask(msg.Ask);
      }
      if (high2 !== msg.High) {
        sethigh2(msg.High);
      }
      if (low2 !== msg.Low) {
        setlow2(msg.Low);
      }
      if (msg.LTP - msg.Previous_Close !== chng) {
        setchng(msg.LTP - msg.Previous_Close);
        setpercent(((msg.LTP - msg.Previous_Close) / msg.Previous_Close) * 100);
      }
      if (ltp !== msg.LTP) {
        setltp(msg.LTP);
      }
      if (open !== msg.Open) {
        setOpen(msg.Open);
      }
      if (close !== msg.Previous_Close) {
        setClose(msg.Previous_Close);
      }
      if (date !== msg.Timestamp) {
        setDate(msg.Timestamp);
      }
    }
  };

  const bidask = (msg) => {
    if (bid !== msg.Bid) {
      setbid(msg.Bid);
      setBidTick(true);

      // setTimeout(() => {
      //   setBidTick(false);
      // }, 100);
    }
    if (ask !== msg.Ask) {
      setask(msg.Ask);
      setAskTick(true);

      // setTimeout(() => {
      //   setAskTick(false);
      // }, 100);
    }
    if (date !== msg.Timestamp) {
      setDate(msg.Timestamp);
    }
  };

  const delete_script = (a) => {
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      a_id: localStorage.getItem("adminsId"),
      watchlist_id: a,
    };

    php.delete_script(data).then((r) => {
      if (r.error == "False") {
        addNotification(r.message, "success");
        props.loadWatchlist();
      } else {
        addNotification(r.message, "error");
      }
    });
  };

  return (
    <>
      <div
        key={props.index}
        onClick={() => showModal()}
        style={{
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 10,
          borderRadius: 10,
          position: "relative",
          borderStyle: "solid",
          borderWidth: 1,
          paddingLeft: 10,
          borderColor: COLORSCHEMEA.THEMEFONT,
        }}
      >
        <div
          style={{
            flex: 2,
            display: "flex",
            borderRightStyle: "solid",
            flexDirection: "column",
            height: "100%",
            alignItems: "flex-start",
            borderRightWidth: 1,
            borderRightColor: "lightgray",
            justifyContent: "center",
          }}
        >
          <div>{props.item.symbol_display}</div>
          <span
            style={{
              fontSize: 10,
              fontWeight: "bold",
              color: COLORSCHEMEA.THEMEFONT,
            }}
          >
            {moment(date).format("DD-MMM-YY")}
            {moment(date).format("HH:mm:ss")}
          </span>
        </div>
        <BidaskCard value={bid} />
        <BidaskCard value={ask} />

        <div
          style={{
            flex: 1,
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            borderRightWidth: 1,
            borderRightColor: "lightgray",
            borderRightStyle: "solid",
            color: COLORSCHEMEA.THEMEFONT,
            fontWeight: "700",
          }}
        >
          {ltp}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            borderRightWidth: 1,
            borderRightColor: "lightgray",
            borderRightStyle: "solid",
            color: chng > 0 ? COLORSCHEMEA.GREENCOLOR : COLORSCHEMEA.REDCOLOR,
          }}
        >
          <div style={{ fontSize: 14 }}> {parseFloat(chng).toFixed(2)} </div>
          <div>{"(" + parseFloat(percent).toFixed(2) + "%)"}</div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            borderRightWidth: 1,
            fontWeight: "500",
            borderRightColor: "lightgray",
            borderRightStyle: "solid",
            color: COLORSCHEMEA.THEMEGRAY,
          }}
        >
          {open}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "500",
            borderRightWidth: 1,
            borderRightColor: "lightgray",
            borderRightStyle: "solid",
            color: COLORSCHEMEA.THEMEGRAY,
          }}
        >
          {high2}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "500",
            borderRightWidth: 1,
            borderRightColor: "lightgray",
            borderRightStyle: "solid",
            color: COLORSCHEMEA.THEMEGRAY,
            height: "100%",
          }}
        >
          {low2}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            borderRightWidth: 1,
            fontWeight: "500",
            borderRightColor: "lightgray",
            borderRightStyle: "solid",
            color: COLORSCHEMEA.THEMEFONT,
          }}
        >
          {close}
        </div>
        {props.noDelete ? null : (
          <div
            onClick={() => delete_script(item.id)}
            style={{
              flex: 0.5,
              display: "flex",
              borderRightStyle: "solid",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              borderRightWidth: 0,
              borderRightColor: "lightgray",
            }}
          >
            <i
              className="pi pi-times"
              style={{
                color: COLORSCHEMEA.REDCOLOR,
                fontSize: 18,
                width: "50%",
              }}
            ></i>
          </div>
        )}
      </div>
      {showManual == true ? (
        <div
          key={props.index}
          style={{
            height: "60px",
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            marginBottom: 10,
            borderRadius: 10,
            borderTopLeftRadius: showManual == true ? 0 : 10,
            borderTopRightRadius: showManual == true ? 0 : 10,
            position: "relative",
            borderStyle: "solid",
            borderWidth: 1,
            paddingLeft: 10,
            borderColor: COLORSCHEMEA.THEMEGRAY,
          }}
        >
          <div
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-evenly",
              gap: 10,
            }}
          >
            <InputText
              type="text"
              style={{ height: "40px" }}
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              placeholder="Enter Username"
            />
            <InputText
              type="text"
              style={{ height: "40px" }}
              onChange={(e) => setQty(e.target.value)}
              value={qty}
              placeholder="Enter Qty"
            />
            <InputText
              type="text"
              style={{ height: "40px" }}
              onChange={(e) => setPrice(e.target.value)}
              value={price}
              placeholder="Enter Rate"
            />
          </div>
          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "space-evenly",
              gap: 10,
            }}
          >
            <Button
              type="button"
              label="BUY"
              // severity="success"
              style={{
                height: 35,
                width: 100,
                fontSize: 14,
                padding: 10,
                backgroundColor: COLORSCHEMEA.GREENCOLOR,
                borderWidth: 0,
              }}
              onClick={() => manual_trade("BUY")}
              data-pr-tooltip="XLS"
            />
            <Button
              type="button"
              label="SELL"
              // severity="danger"
              style={{
                height: 35,
                width: 100,
                fontSize: 14,
                padding: 10,
                backgroundColor: COLORSCHEMEA.REDCOLOR,
                borderWidth: 0,
              }}
              onClick={() => manual_trade("SELL")}
              data-pr-tooltip="XLS"
            />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default WatchlistCard;
