import React from "react";
import {
  FaUser,
  FaUserCheck,
  FaChartPie,
  FaDollarSign,
  FaLock,
  FaUserPlus,
} from "react-icons/fa";
import { MdAccountBalance, MdCreditCard } from "react-icons/md";
import { RiMoneyRupeeCircleFill } from "react-icons/ri";
import { Button } from "primereact/button";
import moment from "moment";
import { apiCall } from "../../../Backend/Backend";
import ClientDetailsCard from "./ClientDetailsCard";
import { useNotification } from "../../Notification/NotificationContext";
import { COLORSCHEMEA } from "../../../ColorSchema/ColorSchema";
import styled from "styled-components";
import NumberFormatIn from "../../Common/NumberFormatIn";
import { ToWords } from "to-words";
import { IoPieChart } from "react-icons/io5";
import { AiFillPieChart } from "react-icons/ai";
const ResponsiveDiv = styled.div`
  display: flex;
  height: calc(100vh - 55px);
  width: 100%;
  padding: 10px;

  /* Default layout: row direction */
  flex-direction: row;

  /* Media query for screen widths less than 1000px */
  @media (max-width: 1000px) {
    flex-direction: column;
    overflow-y: scroll; /* Makes the container scrollable if content overflows */
    padding: 0px;
  }
`;

const StyledDiv = styled.div`
  border-style: solid;
  height: 100%;
  border-width: 1px;
  border-color: ${(props) => props.borderColor || COLORSCHEMEA.THEMEFONT};
  border-radius: 5px;
  flex: 1;
  overflow-y: auto;
  margin-left: 10px;
  @media (max-width: 1000px) {
    margin: 10px;
    &:last-child {
      margin-top: 0;
    }
  }
`;

const Container = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: flex-start;
  border-bottom-style: solid;
  border-width: 0.4px;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const Subtitle = styled.div`
  font-size: 12px;
`;

export default function AddClient({ reload }) {
  const [loading, setLoading] = React.useState(true);
  const { addNotification } = useNotification();
  const [buttonsData, setButtonsData] = React.useState([]);
  const [overviewClientData, setOverviewClientData] = React.useState([]);
  const [brokerList, setBrokerList] = React.useState([]);

  const [formData, setFormData] = React.useState({
    name: "",
    username: "",
    password: "",
    balance: "",
    credit: "",
    rent: "",
    fixed_rent: "0",
    brokerage: "",
    master_id: {},
    admin_id: {},
  });
  const [clients, setUsers] = React.useState([]);
  const [clientsFilter, setUsersFilter] = React.useState([]);

  const [brokerFormData, setBrokerFormData] = React.useState({
    broker_id: {},
    broker_rent: "",
    broker_brokerage: "",
    broker_sharing: "",
  });

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: true,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "",
        plural: "",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const formConfig = [
    { key: "name", placeholder: "e John Bond", type: "text", Icon: FaUser },
    {
      key: "username",
      placeholder: "eg. JohnB07",
      type: "text",
      Icon: FaUserCheck,
      maxlength: 10,
    },
    {
      key: "password",
      placeholder: "******",
      type: "text",
      Icon: FaLock,
    },
    {
      key: "balance",
      placeholder: "eg. 1,00,000",
      type: "number",
      Icon: MdAccountBalance,
    },
    {
      key: "credit",
      placeholder: "eg. 1,00,00,000",
      type: "number",
      Icon: MdCreditCard,
    },

    {
      key: "rent",
      placeholder: "eg. 18%",
      type: "number",
      title: "Rent / Yr",
      Icon: FaDollarSign,
    },
    {
      key: "fixed_rent",
      placeholder: "eg. 1,00,00,000",
      type: "number",
      Icon: FaDollarSign,
    },
    {
      key: "brokerage",
      placeholder: "eg. 0.250",
      type: "number",
      title: "Brokerage / Cr",
      maxlength: 8,
      Icon: FaDollarSign,
    },
  ];

  const brokerFormConfig = [
    {
      key: "broker_rent",
      title: "Broker Rent / Yr",
      placeholder: "eg. 1%",
      type: "number",
      Icon: IoPieChart,
    },
    {
      key: "broker_brokerage",
      placeholder: "eg. 0.05",
      title: "Broker Brokerage / Cr",
      type: "number",
      Icon: AiFillPieChart,
    },
    {
      key: "broker_sharing",
      placeholder: "eg. 50%",
      type: "number",
      Icon: FaChartPie,
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBrokerChange = (e) => {
    const { name, value } = e.target;
    setBrokerFormData({
      ...brokerFormData,
      [name]: value,
    });
  };

  const load_brokers = async (master_id) => {
    let data = {
      m_id: master_id,
    };
    const response = await apiCall("/load_brokers", data);

    setLoading(false);
    setBrokerList(response.brokers);
  };

  React.useEffect(() => {
    load_masters();
  }, []);

  // const load_admins = async () => {
  //   const response = await apiCall("/load_admins");
  //   setLoading(false);
  //   setAdmins(response.admins);
  //   setAdminsFilter(response.admins);
  // };

  const load_masters = async () => {
    const response = await apiCall("/load_masters");
    setLoading(false);
    setUsers(response.users);
    setUsersFilter(response.users);
  };

  const validateData = (userData, brokerData) => {
    // Validate username length
    if (userData.username) {
      if (userData.username.length < 2 || userData.username.length > 10) {
        addNotification(
          "Validation failed: Username must be more than 2 characters and less than 10 characters.",
          "error"
        );
        return false;
      }
    }

    if (userData.password) {
      if (userData.password.length < 4 || userData.password.length > 12) {
        addNotification(
          "Validation failed: Password must be more than 4 characters and less than 12 characters.",
          "error"
        );
        return false;
      }
    }

    // Validate numeric fields in userData
    for (const key of [
      "balance",
      "credit",
      "rent",
      "brokerage",
      "fixed_rent",
    ]) {
      if (userData[key] !== undefined) {
        if (key === "fixed_rent" || key === "balance") {
          // Special check for fixed_rent: must be greater than 0
          if (isNaN(userData[key]) || Number(userData[key]) < 0) {
            addNotification(
              `Validation failed: ${key.toUpperCase()} must be greater than or equal to 0.`,
              "error"
            );
            return false;
          }
        } else if (isNaN(userData[key]) || Number(userData[key]) <= 0) {
          addNotification(
            `Validation failed: ${key.toUpperCase()} must be greater than 0.`,
            "error"
          );
          return false;
        }
      }
    }

    // Additional validation for credit >= balance
    if (userData.credit && userData.balance) {
      if (Number(userData.credit) < Number(userData.balance)) {
        addNotification(
          "Validation failed: Credit must be greater than or equal to Balance.",
          "error"
        );
        return false;
      }
    }

    // Validate broker fields if broker_id.id > 0
    if (brokerData.broker_id && Number(brokerData.broker_id.id) > 0) {
      for (const key of ["broker_rent", "broker_brokerage", "broker_sharing"]) {
        if (
          brokerData[key] === "" ||
          brokerData[key] === null ||
          brokerData[key] === undefined
        ) {
          addNotification(
            `Validation failed: ${key.toUpperCase()} cannot be empty`,
            "error"
          );
          return false;
        }
      }

      // Ensure broker_sharing is between 0 and 100
      if (brokerData.broker_sharing != null) {
        const brokerSharing = Number(brokerData.broker_sharing);

        if (isNaN(brokerSharing) || brokerSharing < 0 || brokerSharing > 100) {
          addNotification(
            "Validation failed: Broker sharing must be between 0 and 100.",
            "error"
          );
          return false;
        }
      }

      // Ensure broker_rent is less than user rent
      if (brokerData.broker_rent != null && userData.rent != null) {
        const brokerRent = Number(brokerData.broker_rent);
        const rent = Number(userData.rent);

        if (!isNaN(brokerRent) && !isNaN(rent) && brokerRent >= rent) {
          addNotification(
            "Validation failed: Broker rent must be less than the overall rent.",
            "error"
          );
          return false;
        }
      }

      // Ensure broker_brokerage is less than user brokerage
      if (brokerData.broker_brokerage != null && userData.brokerage != null) {
        const brokerBrokerage = Number(brokerData.broker_brokerage);
        const brokerage = Number(userData.brokerage);

        if (
          !isNaN(brokerBrokerage) &&
          !isNaN(brokerage) &&
          brokerBrokerage >= brokerage
        ) {
          addNotification(
            "Validation failed: Broker brokerage must be less than the overall brokerage.",
            "error"
          );
          return false;
        }
      }
    }

    return true; // All values are valid
  };

  const add_client = async () => {
    let data = {
      user_data: formData,
      broker_data: brokerFormData,
      m_id: formData.master_id.id,
      name: formData.name,
      username: formData.username,
      password: formData.password,
      balance: formData.balance,
      credit: formData.credit,
      rent: formData.rent,
      fixed_rent: formData.fixed_rent,
      brokerage: formData.brokerage,
      broker_rent: brokerFormData.broker_rent,
      broker_brokerage: parseFloat(
        (parseFloat(brokerFormData.broker_brokerage) * 100) /
          parseFloat(formData.brokerage)
      ).toFixed(3),
      broker_id:
        brokerFormData.broker_id?.id > 0 ? brokerFormData.broker_id?.id : 0,
      broker_sharing: brokerFormData.broker_sharing,
    };

    // Validate data
    const isValid = validateData(formData, brokerFormData);

    if (!isValid) {
      console.error("Validation failed: Some fields are empty or invalid.");
      return; // Exit the function if validation fails
    }

    // Proceed with API call if data is valid
    try {
      setLoading(true);
      const response = await apiCall("/add_user", {
        method: "POST",
        body: JSON.stringify(data),
      });
      addNotification(response.message, "success");
      if (response.error == "False") {
        setFormData({
          name: "",
          username: "",
          password: "",
          balance: "",
          credit: "",
          rent: "",
          fixed_rent: "",
          brokerage: "",
          master_id: {},
        });

        setBrokerFormData({
          broker_id: {},
          broker_rent: "",
          broker_brokerage: "",
          broker_sharing: "",
        });
      }
    } catch (error) {
      console.error("Error adding client:", error);
    } finally {
      reload();
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (formData.master_id && formData.master_id.id) {
      load_brokers(formData.master_id.id);
    }
  }, [formData.master_id]);

  const handleMasterId = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      master_id: e ? e : {}, // If e is undefined, set master_id to {}
    }));
  };

  return (
    <ResponsiveDiv>
      <StyledDiv className="animate__animated animate__zoomIn animate__faster">
        <Container>
          <Content>
            <Title>Add Client</Title>
            <Subtitle>Basic Details</Subtitle>
          </Content>
          <Button
            type="button"
            size="small"
            label="Create"
            severity="primary"
            onClick={add_client}
          />
        </Container>

        <ClientDetailsCard
          Icon={FaUserPlus}
          dropDown
          title={"Master"}
          name={"id"}
          brokerList={clients}
          brokerId={formData?.master_id}
          onChange={handleMasterId}
          placeholder={"Select "}
        />

        {formData.master_id.id &&
          Object.keys(formData).map((key) => {
            if (key === "fixed_rent" || key === "master_id") return null; // Skip "fixed_rent"
            const config = formConfig.find((item) => item.key === key);
            const maxLength = config?.maxlength ? config.maxlength : "unset";
            return (
              <ClientDetailsCard
                Icon={config?.Icon}
                input
                type={config?.type || "text"}
                name={key}
                title={config?.title || key}
                key={key}
                value={formData[key]}
                numberToText={
                  (key === "balance" ||
                    key === "credit" ||
                    key === "rent" ||
                    key === "fsq_limit" ||
                    key === "script_wise_margin" ||
                    key === "brokerage") &&
                  formData[key] !== ""
                    ? toWords.convert(
                        key === "brokerage"
                          ? formData[key] * 100000
                          : formData[key]
                      )
                    : ""
                }
                onChange={handleChange}
                placeholder={config?.placeholder || ""}
                maxLength={maxLength}
              />
            );
          })}
      </StyledDiv>

      <StyledDiv className="animate__animated animate__zoomIn animate__faster">
        <Container>
          <Content>
            <Title>Broker Settings</Title>
            <Subtitle>Control Brokerages and more</Subtitle>
          </Content>
        </Container>

        {formData.master_id.id && (
          <>
            <ClientDetailsCard
              Icon={FaUserPlus}
              dropDown
              title={"Broker"}
              name={"broker_id"}
              brokerList={brokerList}
              brokerId={brokerFormData?.broker_id}
              onChange={(e) =>
                setBrokerFormData({ ...brokerFormData, broker_id: e })
              }
              placeholder={"Select "}
            />

            {brokerFormData?.broker_id?.id > 0 &&
              Object.keys(brokerFormData).map((key) => {
                if (key === "broker_id") {
                  return null;
                }
                const brokerConfig = brokerFormConfig.find(
                  (item) => item.key === key
                );
                return (
                  <ClientDetailsCard
                    Icon={brokerConfig?.Icon}
                    input
                    type={brokerConfig?.type || "text"} // Default to 'text' if no type is specified
                    name={key}
                    title={brokerConfig?.title || key}
                    key={key}
                    value={brokerFormData[key]}
                    onChange={handleBrokerChange}
                    placeholder={brokerConfig?.placeholder || ""}
                    // maxLength={"unset"}
                    numberToText={
                      (key === "broker_sharing" ||
                        key === "broker_rent" ||
                        key === "broker_brokerage") &&
                      brokerFormData[key] !== ""
                        ? toWords.convert(
                            key === "broker_brokerage"
                              ? brokerFormData[key] * 100000
                              : brokerFormData[key]
                          )
                        : ""
                    }
                  />
                );
              })}
          </>
        )}
      </StyledDiv>
    </ResponsiveDiv>
  );
}
