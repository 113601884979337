import { useWindowSize } from "@react-hook/window-size";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { apiCall } from "../../../Backend/Backend";
import NumberFormatIn from "../../Common/NumberFormatIn";
import UpdateTrade from "../../Common/Update";
import { NewTheme } from "../../Theme/Theme";

export default function LiveTrade() {
  const [liveTradeData, setLiveTradeData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [width, height] = useWindowSize();
  const scrollHieght = height - 60;

  React.useEffect(() => {
    loadLiveTradeData();
  }, []);

  const rowClassName = (rowData) => {
    return rowData.short == 1 ? "highlighted-row" : "";
  };

  const loadLiveTradeData = async () => {
    const response = await apiCall("/load_live_transaction");
    setLoading(false);
    setLiveTradeData(response?.transactions);
  };

  return (
    <>
      <UpdateTrade update={() => loadLiveTradeData()} />
      <DataTable
        stripedRows={"red"}
        showGridlines
        scrollable
        value={liveTradeData}
        scrollHeight={scrollHieght}
        rowClassName={rowClassName}
        size="small"
        loading={loading}
        style={{ fontWeight: "bold", fontSize: 14 }}
      >
        <Column
          field="admin_username"
          showFilterMenu={false}
          header="Server"
          style={{ width: "10%" }}
          body={(rowData) => (
            <>
              <div
                style={{
                  color: NewTheme.MainColorExcel,
                  textTransform: "uppercase",
                }}
              >
                {rowData.admin_username}
              </div>
            </>
          )}
        />
        <Column
          field="master"
          showFilterMenu={false}
          header="Master"
          style={{ width: "10%" }}
          body={(rowData) => (
            <>
              <div
                style={{
                  color: NewTheme.MainColorExcel,
                  textTransform: "uppercase",
                }}
              >
                {rowData.master_username}
              </div>
            </>
          )}
        />
        <Column
          field="username"
          showFilterMenu={false}
          header="Client"
          style={{ width: "10%" }}
          body={(rowData) => (
            <>
              <div
                style={{
                  // color: NewTheme.MainColor,
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {rowData.username}
                <span style={{ fontSize: 10 }}>
                  {rowData.short == "1"
                    ? "  " + rowData.short_diff + " Min"
                    : ""}
                </span>
              </div>
            </>
          )}
        />
        <Column
          field="symbol"
          showFilterMenu={false}
          header="Symbol"
          style={{ width: "10%" }}
          body={(rowData) => (
            <>
              <div style={{ color: NewTheme.MainColorExcel }}>
                {rowData.symbol}
              </div>
            </>
          )}
        />
        <Column
          field="qty"
          showFilterMenu={false}
          header="Qty"
          style={{ width: "10%" }}
          body={(rowData) => (
            <div
              style={{
                color:
                  rowData.buy_sell == 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              {<NumberFormatIn value={rowData.qty} />}
            </div>
          )}
        />
        <Column
          field="buy_sell"
          showFilterMenu={false}
          header="B/S"
          style={{ width: "10%" }}
          body={(rowData) => (
            <div
              style={{
                color:
                  rowData.buy_sell == 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              {rowData.buy_sell == 0 ? "Buy" : "Sell"}
            </div>
          )}
        />
        <Column
          field="rate"
          style={{ width: "10%" }}
          showFilterMenu={false}
          header="Rate"
        />
        <Column
          style={{ width: "10%" }}
          field="trade_msg"
          body={(rowData) => (
            <div
              style={{
                color:
                  rowData.trade_msg == "ENTRY"
                    ? NewTheme.MainColor
                    : rowData.trade_msg == "ADDED"
                    ? NewTheme.greencolor
                    : rowData.trade_msg == "REMOVE"
                    ? "#FFA500"
                    : NewTheme.redcolor,
                flexDirection: "column",
                display: "flex",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {rowData.trade_msg}
                <span
                  style={{ color: "black", fontSize: 10, textAlign: "left" }}
                >
                  {rowData.pending_pass == 1 ? " (P)" : null}
                </span>
              </div>
              {rowData.short == "1" ? (
                <span style={{ fontSize: 10, color: "black" }}>
                  {rowData.short_type}
                </span>
              ) : null}
            </div>
          )}
          showFilterMenu={false}
          header="Details"
        />
        <Column
          field="profit_loss"
          showFilterMenu={false}
          header="P/L"
          style={{ width: "10%" }}
          body={(rowData) => {
            const [whole, decimal] = rowData.profit_loss.toString().split(".");
            return (
              <div
                style={{
                  fontWeight: "bold",
                  color:
                    rowData.profit_loss >= 0
                      ? NewTheme.greencolor
                      : NewTheme.redcolor,
                }}
              >
                <NumberFormatIn value={whole} />
                {decimal && (
                  <>
                    .
                    <span style={{ fontSize: 10, opacity: 0.7 }}>
                      {" " + decimal}
                    </span>
                  </>
                )}
              </div>
            );
          }}
        />
        <Column
          field="date_created"
          style={{ width: "10%" }}
          showFilterMenu={false}
          body={(rowData) => (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                {moment(rowData.date_created).format("DD-MMM")}
                <span style={{ color: NewTheme.MainColor }}>
                  {" " + moment(rowData.date_created).format("HH:mm:ss")}
                </span>
              </div>
              {rowData.short == "1" ? (
                <div style={{ fontSize: 10, color: "black" }}>
                  {moment(rowData.previous_trade).format("DD-MMM")}
                  <span style={{ color: NewTheme.MainColor }}>
                    {" " + moment(rowData.previous_trade).format("HH:mm:ss")}
                  </span>
                </div>
              ) : null}
            </div>
          )}
          header="Date"
        />
      </DataTable>
    </>
  );
}
