import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React from "react";
import { RiEdit2Line } from "react-icons/ri";
import styled, { css } from "styled-components";
import { COLORSCHEMEA } from "../../../ColorSchema/ColorSchema";
import NumberFormatIn from "../../Common/NumberFormatIn";

function capitalizeFirstLetter(str) {
  if (typeof str !== "string" || str.length === 0) {
    return str;
  }

  str = str.replace(/_/g, " ");
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export const BalanceInput = styled.input`
  background-color: white;
  color: rgb(0, 0, 0);
  transition: all 0.2s ease 0s;
  height: 40px;
  border-radius: 5px;
  width: 50%;
  border: 0.5px solid lightgray;
  padding-left: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: #edebfa;
      color: ${COLORSCHEMEA.THEMEFONT};
      font-weight: bold;
    `}
`;
export default function ClientDetailsCard({
  name,
  details,
  title,
  edit,
  Icon,
  subDetails,
  maxLength = "unset",
  type,
  placeholder,
  color = "unset",
  input,
  brokerList = [],
  brokerId = {},
  dropDown,
  value,
  onChange,
  onClick,
  disabled,
  numberToText = "",
  numberToTextView = "",
  flex = "unset",
}) {
  React.useEffect(() => {
    const handleWheel = (e) => {
      if (e.target.type === "number") {
        e.preventDefault();
      }
    };
    const handleArrowKeys = (e) => {
      if (
        e.target.type === "number" &&
        (e.key === "ArrowUp" || e.key === "ArrowDown")
      ) {
        e.preventDefault();
      }
    };

    // Attach non-passive event listeners
    window.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("keydown", handleArrowKeys);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("keydown", handleArrowKeys);
    };
  }, []);

  const lineHeight = 30; // Approximate line height in pixels
  const baseHeight = 50; // Base height when numberToText is empty
  const maxHeight = 150; // Maximum height to avoid overflow

  const calculatedHeight =
    numberToText === ""
      ? baseHeight
      : Math.min(
          baseHeight + lineHeight * numberToText.split("\n").length,
          maxHeight
        );

  const formatTextWithValue = (name, value, numberToText) => {
    if (!numberToText) return "";
    const formattedValue = (
      <NumberFormatIn
        value={
          ["brokerage", "min_brokerage"].includes(name)
            ? parseFloat(value) * 100000
            : value
        }
      />
    );

    const createFormat =
      (suffix, includePercent = false) =>
      () =>
        (
          <>
            {formattedValue}
            {includePercent && "%"} ({numberToText}) {suffix}
          </>
        );

    const formats = {
      rent: createFormat("per year", true),
      margin: createFormat("per transaction", true),
      script_wise_margin: createFormat("per script"),
      trade_margin: createFormat("trade limit"),
      brokerage: createFormat("per crore"),
      min_brokerage: createFormat("per crore"),
    };

    return formats[name]?.() || numberToText;
  };

  const formatText = formatTextWithValue;
  const formatTextView = formatTextWithValue;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: 5,
          justifyContent: "flex-end",
          height: calculatedHeight,
          // alignItems: "center",
          borderBottomStyle: "solid",
          borderBottomWidth: 0.5,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            height: 50,
            width: "100%",
            alignItems: "center",
          }}
        >
          {Icon && (
            <div
              style={{
                width: 50,
                height: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: COLORSCHEMEA.THEMEFONT,
              }}
            >
              <Icon size={25} />
            </div>
          )}
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              fontWeight: "bold",
              color: COLORSCHEMEA.THEMEGRAY,
            }}
          >
            {capitalizeFirstLetter(title)}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
              paddingRight: 10,
              flexDirection: "column",
              fontWeight: "bold",
              flex: flex,
            }}
          >
            {!input ? (
              <>
                <div
                  style={{
                    textTransform: "uppercase",
                    color: color,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {edit && (
                    <div
                      style={{
                        width: 70,
                        height: 40,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={onClick}
                    >
                      <RiEdit2Line size={20} color={COLORSCHEMEA.THEMEFONT} />
                    </div>
                  )}
                  {details}
                </div>
                {subDetails && (
                  <div
                    style={{
                      fontSize: 10,
                      color: COLORSCHEMEA.THEMEGRAY,
                      marginBottom: 5,
                    }}
                  >
                    {subDetails}
                  </div>
                )}
                {numberToTextView && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      alignItems: "center",
                      fontSize: 10,
                      color: COLORSCHEMEA.THEMELIGHT,
                    }}
                  >
                    {formatTextView(name, value, numberToTextView)}
                  </div>
                )}
              </>
            ) : (
              <>
                <InputText
                  type={type}
                  value={value}
                  name={name}
                  onChange={(e) => onChange(e)}
                  placeholder={placeholder}
                  style={{
                    width: "100%",
                    paddingRight: 5,
                    borderWidth: 0.2,
                    backgroundColor: disabled ? "#edebfa" : "initial",
                    color: disabled ? COLORSCHEMEA.THEMEFONT : "initial",
                    // fontWeight: disabled ? "bold" : "initial",
                  }}
                  disabled={disabled}
                  maxLength={maxLength}
                  autoFocus
                />
              </>
            )}

            {dropDown && (
              <Dropdown
                style={{
                  width: "100%",
                  height: "35px",
                  minWidth: 200,
                }}
                onChange={(e) => {
                  onChange(e.value);
                }}
                options={brokerList}
                value={brokerId}
                name={name}
                optionLabel="username"
                placeholder={placeholder}
                filter
                showClear
              />
            )}
          </div>
        </div>
        {numberToText && (
          <div
            style={{
              display: "flex",
              marginRight: 20,
              justifyContent: "flex-end",
              height: 20,
              width: "100%",
              alignItems: "center",
              fontSize: 12,
              color: COLORSCHEMEA.THEMEGRAY,
              fontWeight: "bold",
            }}
          >
            {formatText(name, value, numberToText)}
          </div>
        )}
      </div>
    </>
  );
}
